import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext'; // Import the ThemeProvider
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Collections from './pages/Collections';
import CollectionObjkt from './pages/CollectionObjkt';
import CollectionFnd from './pages/CollectionFnd';
import CollectionZora from './pages/CollectionZora';
import TokenObjkt from './pages/TokenObjkt';
import TokenFnd from './pages/TokenFnd';
import TokenZora from './pages/TokenZora';
import Objkt from './pages/Objkt';
import Foundation from './pages/Foundation';
import Zora from './pages/Zora';
import Faq from './pages/Faq';
import AnalogicoDigitale from './pages/AnalogicoDigitale';
import './DarkMode.scss';
import './App.scss';

function App() {
  return (
    <ThemeProvider> {/* Wrap the app with the ThemeProvider */}
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/objkt" element={<Objkt />} />
          <Route path="/foundation" element={<Foundation />} />
          <Route path="/zora" element={<Zora />} />
          <Route path="/objkt/collection" element={<CollectionObjkt />} />
          <Route path="/foundation/collection" element={<CollectionFnd />} />
          <Route path="/zora/collection" element={<CollectionZora />} />
          <Route path="/objkt/token" element={<TokenObjkt />} />
          <Route path="/foundation/token" element={<TokenFnd />} />
          <Route path="/zora/token" element={<TokenZora />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/analogic-digital" element={<AnalogicoDigitale />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;