import './AnalogicoDigitale.scss';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';

const AnalogicoDigitale = () => {
  const [fullscreenMedia, setFullscreenMedia] = useState(null);

  // Mapping tra video e i rispettivi poster
  const videoPosterMapping = {
    '/video/rome-1.mp4': '/video/poster-1.png',
    '/video/rome-2.mp4': '/video/poster-2.png',
    '/video/rome-3.mp4': '/video/poster-3.png',
    '/video/milano-3.mp4': '/video/poster-4.png',
    '/video/napoli-1.mp4': '/video/poster-5.png',
    '/video/napoli-3.mp4': '/video/poster-6.png',
    '/video/florence-1.mp4': '/video/poster-7.png',
    '/video/florence-2.mp4': '/video/poster-8.png',
    '/video/florence-3.mp4': '/video/poster-9.png',
    '/video/bologna-1.mp4': '/video/poster-10.png',
    '/video/bologna-2.mp4': '/video/poster-11.png',
  };

  const openFullscreen = (media) => {
    setFullscreenMedia(media);
  };

  const closeFullscreen = () => {
    setFullscreenMedia(null);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape' && fullscreenMedia) {
        closeFullscreen();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [fullscreenMedia]);

  useEffect(() => {
    gsap.fromTo('.intro', { opacity: 0, y: 50 }, { duration: 0.5, opacity: 1, y: 0, ease: 'power2.out' });
    gsap.fromTo('.portfolio-item', { opacity: 0, y: 20 }, { duration: 0.5, opacity: 1, y: 0, stagger: 0.3, ease: 'power2.out', delay: 0.2 });
    gsap.fromTo('.exhibition-item', { opacity: 0, y: 20 }, { duration: 0.5, opacity: 1, y: 0, stagger: 0.3, ease: 'power2.out', delay: 0.5 });
  }, []);

  return (
    <main className="main">
      <Helmet>
        <title>Analogic and Digital</title>
      </Helmet>
      <section className="section section--intro">
        <div className="container">
          <div className="intro">
            <h1>Analogic and Digital</h1>
            <p>
              A selection of my best works, inspired by the intersection between the analog and digital worlds. This is my participation in the Dimensions DAO exhibition.
            </p>
          </div>
        </div>
      </section>
      <section className="section section--portfolio">
        <div className="container">
          <h2>Selected artworks</h2>
          <div className="portfolio">
            {[
              { src: '/nft/QmQvyNEf66GBfyya7Q5ZUrMa7jkoh4nQEkgGRueXHD3xqW.gif', title: 'Love songs' },
              { src: '/nft/bafybeicui232vwamcg7chtrgkngapvf7euewqgw3rfjizb6oioz4g2nngi.gif', title: 'Analog' },
              { src: '/nft/bafybeidkayt5zv2krsu72fgytgklsbgjdsnqygnn4kwjd5mxbgiduqofbu.gif', title: 'Taste the fun' },
              { src: '/nft/bafybeidxrjmcmivvzagaz6lb27aucwzknon7gqwiwsyy5fjituqdglw7ea.jpg', title: 'Technivorous' },
              { src: '/nft/bafybeiedoxmf55cdjrygfdtv4gyjwqizrwvpvq2ufqldvs4hsizbrtjiim.jpg', title: 'Dream machine' },
              { src: '/nft/bafybeidsmulomo46fs3p423imqfqd3xs66ikvzk64wihkqobb5qaoltmju.jpg', title: 'Streets of love' },
              { src: '/nft/bafybeiecw2beodljuvcrzid63bq5xffdmplcf5k7fxd44xxxtfhyxjpoba.gif', title: 'Saturday morning' },
              { src: '/nft/bafybeiffit4rks4wwl7twf6a5bp5u4jpdjsrussgx7i4u4ar4ajlxpv45m.gif', title: "Good 'n cold" },
              { src: '/nft/bafybeih22ihqqbnwr4onmzaoaw7uwdosfgrxtrnjbgcg5waqktcsljgc2a.gif', title: 'Out run' },
            ].map((artwork, index) => (
              <div className="portfolio-item" key={index}>
                <div className="item-content" onClick={() => openFullscreen(artwork.src)}>
                  <img src={artwork.src} alt={artwork.title} />
                  <div className="title">{artwork.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section section--exhibitions">
        <div className="container">
          <h2>Past exhibitions</h2>
          <div className="exhibitions">
            {[
              {
                title: 'NFT.cc',
                info: '2024, Rome',
                gallery: ['/video/rome-1.mp4', '/video/rome-2.mp4', '/video/rome-3.mp4'],
              },
              {
                title: 'Meet Digital Culture Center',
                info: '2024, Milano',
                gallery: ['/video/milano-1.jpg', '/video/milano-2.jpg', '/video/milano-3.mp4'],
              },
              {
                title: 'NFT.cc',
                info: '2024, Naples',
                gallery: ['/video/napoli-1.mp4', '/video/napoli-2.jpg', '/video/napoli-3.mp4'],
              },
              {
                title: 'Link Between Worlds',
                info: '2023, Firenze',
                gallery: ['/video/florence-1.mp4', '/video/florence-2.mp4', '/video/florence-3.mp4'],
              },
              {
                title: 'Salida',
                info: '2022, Bologna',
                gallery: ['/video/bologna-1.mp4', '/video/bologna-2.mp4', '/video/bologna-3.jpg'],
              },
            ].map((exhibition, index) => (
              <div className="exhibition-item" key={index}>
                <div className="details">
                  <div className="title">{exhibition.title}</div>
                  <div className="info">{exhibition.info}</div>
                </div>
                <div className="gallery">
                  {exhibition.gallery.map((media, i) => (
                    <div className="item-gallery" key={i} onClick={() => openFullscreen(media)}>
                      {media.endsWith('.mp4') ? (
                        <div className="video-wrapper">
                          <video
                            width="100%"
                            poster={videoPosterMapping[media] || ''}
                            preload="metadata"
                            playsInline
                          >
                            <source src={media} type="video/mp4" />
                          </video>
                          <img
                            className="fallback-poster"
                            src={videoPosterMapping[media] || '/default-fallback.jpg'}
                            alt="Video Fallback"
                          />
                          <div className="play-icon">
                            <span>▶</span>
                          </div>
                        </div>
                      ) : (
                        <img src={media} alt={exhibition.title} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {fullscreenMedia && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <div className="close-fullscreen-image" onClick={closeFullscreen}>
            Close
          </div>
          <div className="fullscreen-content" onClick={(e) => e.stopPropagation()}>
            {fullscreenMedia.endsWith('.mp4') ? (
              <video controls autoPlay>
                <source src={fullscreenMedia} type="video/mp4" />
              </video>
            ) : (
              <img src={fullscreenMedia} alt="Fullscreen Media" />
            )}
          </div>
        </div>
      )}
    </main>
  );
};

export default AnalogicoDigitale;
